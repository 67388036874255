import { ContractWorksheetGridItem } from "@sumer/shared/components/players/contracts/ContractWorksheetGridItem";
import { toCurrency } from "@sumer/shared/utils/dollarUtils";
import {
  ContractSectionFragment,
  ContractSeasonFragment,
  ContractFragment,
} from "../graphql/generated/graphql";

export const SectionType = {
  P5: "P5",
  P5Conv: "P5 CONV G$",
  P5Inj: "P5 Inj G$",
  SigningBonus: "Signing Bonus",
  RosterBonus: {
    NinetyMan: "90-Man Roster",
    FortySixMan: "46-Man Roster",
    FiftyThreeMan: "53-Man Roster",
    PerGame: "PerGame",
  },
  RBConv: "RB CONV G$",
  RBInj: "RB Inj G$",
  OptionBonus: "Option",
  Offseason: "Off-Season",
  OffseasonConv: "Offseason CONV G$",
  ReportingBonus: "Reporting Bonus",
  Incentive: "Incentive",
  Escalator: "Escalator",
};

export const WorksheetItems = [
  "P5 Salary",
  "P5 CONV G$",
  "P5 Inj G$",
  "Signing Bonus",
  "Roster Bonus: 90",
  "RB CONV G$",
  "RB Inj G$",
  "Roster Bonus: 46",
  "Roster Bonus: 53",
  "Roster Bonus: PerGame",
  "Option Bonus",
  "Offseason",
  "Offseason CONV G$",
  "Reporting Bonus",
  "Incentive",
  "Escalator",
  "Practical Guarantee",
  "Carryover: Existing Cash",
  "Carryover: Cap",
];

export enum WorksheetItemEnum {
  P5 = "P5 Salary",
  P5Conv = "P5 CONV G$",
  P5Inj = "P5 Inj G$",
  RB90 = "Roster Bonus: 90",
  RBConv = "RB CONV G$",
  OptionBonus = "Option Bonus",
  Offseason = "Offseason",
  OffseasonConv = "Offseason CONV G$",
  Escalator = "Escalator",
  Incentive = "Incentive",
  SigningBonus = "Signing Bonus",
  ReportingBonus = "Reporting Bonus",
}

export const GuaranteeType = {
  Skill: "Skill",
  Injury: "Injury",
  Cap: "Cap",
  Practical: "Practical",
};

export const SigningType = {
  SelectionListSigning: "Selection List Signing",
  RenegotiationExtension: "Renegotiation/Extension",
  RFASigning: "RFA Signing",
  FranchisePlayerSigning: "Franchise Player Signing",
};

export const getContractYears = (
  firstContractYear: number,
  effectiveLastContractyear: number,
  signingType?: string
) => {
  if (signingType == SigningType.SelectionListSigning) return 4;
  return effectiveLastContractyear - firstContractYear + 1;
};

export const getExtensionYears = (
  firstContractYear: number,
  effectiveLastContractyear: number,
  existingYears: number
) => {
  return (
    getContractYears(firstContractYear, effectiveLastContractyear) -
    existingYears
  );
};

export const getExtensionPackage = (
  totalPkg: number,
  existingAmount: number
) => {
  return totalPkg - existingAmount;
};

export const getExtensionAPY = (
  totalPkg: number,
  existingAmount: number,
  firstContractYear: number,
  effectiveLastContractyear: number,
  existingYears: number
) => {
  const extPkg = getExtensionPackage(totalPkg, existingAmount);
  if (extPkg <= 0) return 0;
  const extYears = getExtensionYears(
    firstContractYear,
    effectiveLastContractyear,
    existingYears
  );
  if (extYears == 0) return 0;

  return extPkg / extYears;
};

export const getWorksheetComment = (
  season: number,
  worksheetItem: string,
  overrides: ContractWorksheetGridItem[]
) => {
  const overrideComments = overrides.filter(
    (o) => o.season == season && o.item == worksheetItem && o.comments != ""
  );
  if (overrideComments.length > 1) {
    return overrideComments.map((o) => o.comments).join(" / ");
  }

  return overrideComments.length == 1
    ? overrideComments.map((o) => o.comments)
    : "";
};

export const getWorksheetOatsbProrationAmount = (
  item: WorksheetItemEnum,
  amount: number,
  season: number,
  firstContractYear: number,
  lastContractYear: number
) => {
  // if season is outside proration window, return 0
  if (
    season >=
    firstContractYear +
      (getContractYears(firstContractYear, lastContractYear) >= 5
        ? 5
        : getContractYears(firstContractYear, lastContractYear))
  )
    return 0;

  if (item == WorksheetItemEnum.OptionBonus) {
    return getProration(amount, season, lastContractYear);
  }
  if (
    item == WorksheetItemEnum.RB90 ||
    item == WorksheetItemEnum.ReportingBonus ||
    item == WorksheetItemEnum.Offseason ||
    item == WorksheetItemEnum.SigningBonus
  ) {
    return getProration(amount, firstContractYear, lastContractYear);
  }

  return 0;
};

export const getTotalPackage = (
  seasons: ContractSeasonFragment[] | undefined,
  overrides: ContractWorksheetGridItem[],
  firstContractYear: number
) => {
  let total = 0;
  seasons &&
    seasons.forEach((y) => {
      total += getCashEarnedYearForWorksheet(y, overrides, firstContractYear);
    });

  return total;
};
export const getCashEarnedYearForWorksheet = (
  season: ContractSeasonFragment,
  overrides: ContractWorksheetGridItem[],
  firstContractYear: number
) => {
  if (season.isVoidYear) return 0;

  const totalRb90s = getWorksheetItemSeasonTotal(
    WorksheetItemEnum.RB90,
    season.season,
    overrides
  );
  const totalOptionBonus = getWorksheetItemSeasonTotal(
    WorksheetItemEnum.OptionBonus,
    season.season,
    overrides
  );
  const totalWorkout = getWorksheetItemSeasonTotal(
    WorksheetItemEnum.Offseason,
    season.season,
    overrides
  );
  const rbPerGame = getRBPerGame(
    season.rosterBonuses.ninetyMan,
    season.rosterBonuses.fiftyThreeMan,
    season.rosterBonuses.fortySixMan,
    season.rosterBonuses.perGame
  );

  let total =
    season.p5 +
    totalOptionBonus +
    season.rosterBonuses.fortySixMan +
    season.rosterBonuses.fiftyThreeMan +
    rbPerGame +
    totalRb90s +
    season.reportingBonus +
    totalWorkout;
  if (season.season == firstContractYear) {
    total += season.signingBonus;
  }
  return total;
};

// the league likes to duplicate these values
const getRBPerGame = (
  ninetyMan: number,
  fiftyThreeMan: number,
  fortySixMan: number,
  perGame: number
) => {
  if (
    ninetyMan == perGame ||
    fiftyThreeMan == perGame ||
    fortySixMan == perGame
  )
    return 0;
  return perGame;
};

export const getNewGuarantee = (
  contract: ContractFragment | undefined,
  overrides: ContractWorksheetGridItem[]
) => {
  let guarantee = 0;
  if (contract) {
    guarantee = getTotalGuarantee(contract.seasons, overrides);

    if (contract.sumer.existingAmountGuaranteed) {
      guarantee -= contract.sumer.existingAmountGuaranteed;
    }
  }
  return guarantee;
};

export const getTotalThreeYear = (
  seasons: ContractSeasonFragment[] | undefined,
  overrides: ContractWorksheetGridItem[],
  firstContractYear: number
) => {
  const year = firstContractYear + 2;
  const cashEarned =
    seasons &&
    getCashEarnedTotalForWorksheet(year, seasons, overrides, firstContractYear);

  return cashEarned ?? 0;
};

export const getExtensionTotalThreeYear = (
  contract: ContractFragment | undefined,
  overrides: ContractWorksheetGridItem[]
) => {
  let cashEarned = 0;

  if (contract) {
    const year =
      contract.firstContractYear + 2 + (contract.sumer.existingYears ?? 0);
    const thirdYear = contract.seasons.find((x) => x.season == year);

    if (thirdYear) {
      cashEarned = getExtensionCashEarnedTotal(
        thirdYear,
        contract.seasons,
        overrides,
        contract.firstContractYear
      );
    }
  }
  return cashEarned;
};

export const getCashEarnedTotalForWorksheet = (
  season: number,
  seasons: ContractSeasonFragment[],
  overrides: ContractWorksheetGridItem[],
  firstContractYear: number
) => {
  const yearsToAggregate = seasons.filter((y) => y.season <= season);
  let total = 0;

  yearsToAggregate.forEach((yta) => {
    if (!yta.isVoidYear) {
      const totalRb90s = getWorksheetItemSeasonTotal(
        WorksheetItemEnum.RB90,
        yta.season,
        overrides
      );
      const totalOptionBonus = getWorksheetItemSeasonTotal(
        WorksheetItemEnum.OptionBonus,
        yta.season,
        overrides
      );
      const totalWorkout = getWorksheetItemSeasonTotal(
        WorksheetItemEnum.Offseason,
        yta.season,
        overrides
      );
      total +=
        yta.p5 +
        yta.rosterBonuses.fortySixMan +
        yta.rosterBonuses.fiftyThreeMan +
        getRBPerGame(
          yta.rosterBonuses.ninetyMan,
          yta.rosterBonuses.fiftyThreeMan,
          yta.rosterBonuses.fortySixMan,
          yta.rosterBonuses.perGame
        ) +
        yta.reportingBonus +
        totalRb90s +
        totalOptionBonus +
        totalWorkout;
      if (yta.season == firstContractYear) {
        total += yta.signingBonus;
      }
    }
  });

  return total;
};

export const getExtensionCashEarnedYear = (
  season: ContractSeasonFragment,
  firstContractYear: number,
  overrides: ContractWorksheetGridItem[]
) => {
  const cashEarned = getCashEarnedYearForWorksheet(
    season,
    overrides,
    firstContractYear
  );
  const carryOver = season.carryOvers.existingCash;

  return cashEarned - carryOver;
};

export const getExtensionCashEarnedTotalForWorksheet = (
  season: ContractSeasonFragment,
  seasons: ContractSeasonFragment[],
  overrides: ContractWorksheetGridItem[],
  firstContractYear: number
) => {
  if (season.isVoidYear) return 0;
  const cashEarned = getCashEarnedTotalForWorksheet(
    season.season,
    seasons,
    overrides,
    firstContractYear
  );
  const carryOver = getExtensionCarryoverTotal(season.season, seasons);

  return cashEarned - carryOver;
};

export const getExtensionCashEarnedTotal = (
  season: ContractSeasonFragment,
  seasons: ContractSeasonFragment[],
  overrides: ContractWorksheetGridItem[],
  firstContractYear: number
) => {
  if (season.isVoidYear) return 0;
  const cashEarned = getCashEarnedTotalForWorksheet(
    season.season,
    seasons,
    overrides,
    firstContractYear
  );
  const carryOver = getExtensionCarryoverTotal(season.season, seasons);

  return cashEarned - carryOver;
};

const getExtensionCarryoverTotal = (
  season: number,
  seasons: ContractSeasonFragment[]
) => {
  const yearsToAggregate = seasons.filter((y) => y.season <= season);
  let total = 0;
  yearsToAggregate.forEach((yta) => {
    if (!yta.isVoidYear) {
      total += yta.carryOvers.existingCash;
    }
  });

  return total;
};

export const getExtensionAPYTotal = (
  season: ContractSeasonFragment,
  seasons: ContractSeasonFragment[],
  overrides: ContractWorksheetGridItem[],
  firstContractYear: number,
  existingYears: number | null | undefined
) => {
  if (season.isVoidYear) return 0;
  if (existingYears && season.season < firstContractYear + existingYears)
    return 0;

  let cashEarned = getCashEarnedTotalForWorksheet(
    season.season,
    seasons,
    overrides,
    firstContractYear
  );
  const carryOver = getExtensionCarryoverTotal(season.season, seasons);

  cashEarned = cashEarned - carryOver;

  const apyYear = firstContractYear + (existingYears ?? 0);
  const yearWithinContract = season.season - apyYear + 1;

  return cashEarned / yearWithinContract;
};

export const getApyTotal = (cashEarnedTotal: number, yearWithin: number) => {
  return Math.round(cashEarnedTotal / yearWithin);
};

export const getCapAmount = (
  currentSeason: ContractSeasonFragment,
  overrides: ContractWorksheetGridItem[],
  firstContractYear: number,
  lastContractYear: number
) => {
  const carryOver = currentSeason.carryOvers.cap;
  const totalOptionBonus = getOptionBonusProrationAmtForSeason(
    currentSeason.season,
    lastContractYear,
    overrides
  );
  const totalRb90s = getWorksheetItemCapSeasonTotal(
    WorksheetItemEnum.RB90,
    currentSeason.season,
    overrides
  );

  const rb90_oatsb = getOATSBProrationForTotals(
    WorksheetItemEnum.RB90,
    overrides,
    firstContractYear,
    lastContractYear
  );
  const offseason_oatsb = getOATSBProrationForTotals(
    WorksheetItemEnum.Offseason,
    overrides,
    firstContractYear,
    lastContractYear
  );
  const reportingBonus_oatsb = getOATSBProrationForTotals(
    WorksheetItemEnum.ReportingBonus,
    overrides,
    firstContractYear,
    lastContractYear
  );
  const totalOffseason = getWorksheetItemCapSeasonTotal(
    WorksheetItemEnum.Offseason,
    currentSeason.season,
    overrides
  );

  const total =
    (currentSeason.isVoidYear ? 0 : currentSeason.p5) +
    currentSeason.signingBonusProrated +
    rb90_oatsb +
    totalRb90s +
    currentSeason.rosterBonuses.fortySixMan +
    currentSeason.rosterBonuses.fiftyThreeMan +
    reportingBonus_oatsb +
    carryOver +
    offseason_oatsb +
    totalOffseason;

  return total + totalOptionBonus;
};
const getOATSBProrationForTotals = (
  item: WorksheetItemEnum,
  overrides: ContractWorksheetGridItem[],
  firstContractYear: number,
  lastContractYear: number
) => {
  const oatsbs = overrides.filter((o) => o.item == item && o.prorate);
  let totalOatsb = 0;
  oatsbs.forEach((x) => {
    totalOatsb += getWorksheetOatsbProrationAmount(
      item,
      x?.guaranteedAmount ?? 0,
      x?.season ?? 0,
      firstContractYear,
      lastContractYear
    );
  });

  return totalOatsb;
};
const getOptionBonusProrationAmtForSeason = (
  season: number,
  lastContractYear: number,
  overrides: ContractWorksheetGridItem[]
) => {
  let totalOptionBonus = 0;

  const seasonOptionBonuses = overrides.filter(
    (o) => o.season && o.item == WorksheetItemEnum.OptionBonus
  );

  if (seasonOptionBonuses.length == 0) return 0;

  totalOptionBonus = seasonOptionBonuses.reduce(
    (a, v) =>
      (a =
        a + getOptionBonusProrationForTotals(season ?? 0, lastContractYear, v)),
    0
  );
  return totalOptionBonus;
};

const getWorksheetItemSeasonTotal = (
  item: string,
  season: number,
  overrides: ContractWorksheetGridItem[]
) => {
  let total = 0;

  if (overrides) {
    const seasonTotal = overrides.filter(
      (o) => o.season && o.season == season && o.item == item
    );
    if (seasonTotal.length == 0) return 0;

    total = seasonTotal.reduce((a, v) => (a = a + (v.amount ?? 0)), 0);
  }
  return total;
};
const getWorksheetItemCapSeasonTotal = (
  item: string,
  season: number,
  overrides: ContractWorksheetGridItem[]
) => {
  let total = 0;

  if (overrides) {
    const seasonTotal = overrides.filter(
      (o) => o.season && o.season == season && o.item == item && !o.prorate
    );
    if (seasonTotal.length == 0) return 0;

    total = seasonTotal.reduce((a, v) => (a = a + (v.amount ?? 0)), 0);
  }
  return total;
};
export const getFullGuarantee = (
  seasons: ContractSeasonFragment[] | undefined,
  overrides: ContractWorksheetGridItem[]
) => {
  let total = 0;

  if (seasons) {
    seasons.forEach((y) => {
      total +=
        getGuaranteeMonies(y.guarantees.p5, y.guarantees.p5GuaranteedType) +
        y.signingBonusProrated +
        getGuaranteeMoniesFromOverrides(
          WorksheetItemEnum.Offseason,
          y.season,
          overrides
        ) +
        getGuaranteeMoniesFromOverrides(
          WorksheetItemEnum.Incentive,
          y.season,
          overrides
        ) +
        getGuaranteeMoniesFromOverrides(
          WorksheetItemEnum.ReportingBonus,
          y.season,
          overrides
        ) +
        y.guarantees.rosterBonus46Man +
        y.guarantees.rosterBonus53Man +
        getGuaranteeMoniesFromOverrides(
          WorksheetItemEnum.RB90,
          y.season,
          overrides
        );
    });
  }
  return total;
};

export const getTotalGuarantee = (
  seasons: ContractSeasonFragment[] | undefined,
  overrides: ContractWorksheetGridItem[]
) => {
  if (seasons) {
    return seasons.reduce(
      (a, v) =>
        (a =
          a +
          getGuaranteeMonies(v.guarantees.p5, v.guarantees.p5GuaranteedType) +
          // include conversion here
          getGuaranteeMoniesFromOverrides(
            WorksheetItemEnum.P5Conv,
            v.season,
            overrides
          ) +
          getGuaranteeMoniesFromOverrides(
            WorksheetItemEnum.Offseason,
            v.season,
            overrides
          ) +
          getGuaranteeMoniesFromOverrides(
            WorksheetItemEnum.Incentive,
            v.season,
            overrides
          ) +
          getGuaranteeMoniesFromOverrides(
            WorksheetItemEnum.ReportingBonus,
            v.season,
            overrides
          ) +
          v.guarantees.rosterBonus46Man +
          v.guarantees.rosterBonus53Man +
          getGuaranteeMoniesFromOverrides(
            WorksheetItemEnum.RB90,
            v.season,
            overrides
          ) +
          getGuaranteeMoniesFromOverrides(
            WorksheetItemEnum.RBConv,
            v.season,
            overrides
          ) +
          v.signingBonus +
          getGuaranteeMoniesFromOverrides(
            WorksheetItemEnum.OffseasonConv,
            v.season,
            overrides
          )),
      0
    );
  }
  return 0;
};
const getGuaranteeMonies = (amt: number, type: string) => {
  if (
    type == GuaranteeType.Practical ||
    type == "" ||
    type == GuaranteeType.Injury
  )
    return 0;

  return amt;
};

const getGuaranteeMoniesFromOverrides = (
  item: WorksheetItemEnum,
  season: number,
  overrides: ContractWorksheetGridItem[]
) => {
  let total = 0;
  if (overrides) {
    const seasonItems = overrides.filter(
      (o) => o.season && o.season == season && o.item == item
    );
    if (seasonItems.length == 0) return 0;
    seasonItems.forEach((i) => {
      total += getGuaranteeMonies(i.amount ?? 0, i.guaranteedType ?? "");
    });
  }
  return total;
};
export const getTotalPracticalGuarantee = (
  seasons: ContractSeasonFragment[] | undefined,
  overrides: ContractWorksheetGridItem[]
) => {
  const totalG$ = getTotalGuarantee(seasons, overrides);
  let practicalG$ = 0;
  if (seasons) {
    practicalG$ = overrides
      .filter((x) => x.guaranteedType == GuaranteeType.Practical)
      .reduce((a, v) => (a = a + (v.guaranteedAmount ?? 0)), 0);
  }
  return totalG$ + practicalG$;
};
export const getOptionBonusProrationForTotals = (
  season: number,
  lastContractYear: number,
  override: ContractWorksheetGridItem
) => {
  if (override.season) {
    if (override?.season > season) return 0;
    if (season < override.season) return 0;
    // if season to override is greater 5 it should be 0 as it can only be prorated up to 5 years
    if (season - override.season >= 5) return 0;

    if ((override?.amount ?? 0 > 0) && override?.prorate) {
      return getProration(
        override?.amount ?? 0,
        override.season,
        lastContractYear
      );
    }
  }
  return 0;
};
export const getOptionBonusProrationForWorksheet = (
  season: number,
  lastContractYear: number,
  override: ContractWorksheetGridItem
) => {
  if (override.season) {
    if (override?.season > season) return 0;
    // if season to override is greater 5 it should be 0 as it can only be prorated up to 5 years
    if (season - override.season >= 5) return 0;

    if ((override?.amount ?? 0 > 0) && override?.prorate) {
      return getProration(
        override?.amount ?? 0,
        override.season,
        lastContractYear
      );
    }
  }
  return 0;
};
const getProration = (
  bonus: number,
  bonusYear: number,
  lastContractYear: number
) => {
  if (bonus == 0) return 0;

  let years = 5;
  if (lastContractYear - bonusYear < 5) {
    years = lastContractYear - bonusYear + 1;
  }
  return bonus / years;
};
/// Get total value for a contract section type
/// Sum up the contract years
export const getContractLineTotal = (
  seasons: ContractSeasonFragment[],
  section: string,
  season?: number | undefined
) => {
  let value = 0;
  switch (section) {
    case SectionType.P5:
      value = seasons.reduce(
        (a, v) => (a = a + (v && !v.isVoidYear ? v.p5 : 0)),
        0
      );
      break;
    case SectionType.SigningBonus:
      value = seasons.reduce((a, v) => (a = a + (v && v.signingBonus)), 0);
      break;
    case SectionType.OptionBonus:
      if (season) {
        const s = seasons.filter((f) => f.season == season);
        value = s.reduce((a, v) => (a = a + (v && v.optionBonus)), 0);
      } else {
        value = seasons.reduce(
          (a, v) => (a = a + (v && v.optionBonusProrated)),
          0
        );
      }
      break;
    case SectionType.RosterBonus.NinetyMan:
      value = seasons.reduce(
        (a, v) => (a = a + (v && v.rosterBonuses.ninetyMan)),
        0
      );
      break;
    case SectionType.RosterBonus.FortySixMan:
      value = seasons.reduce(
        (a, v) => (a = a + (v && v.rosterBonuses.fortySixMan)),
        0
      );
      break;
    case SectionType.RosterBonus.FiftyThreeMan:
      value = seasons.reduce(
        (a, v) => (a = a + (v && v.rosterBonuses.fiftyThreeMan)),
        0
      );
      break;
    case SectionType.RosterBonus.PerGame:
      const perGameValue = seasons.reduce(
        (a, v) => (a = a + (v && v.rosterBonuses.perGame)),
        0
      );
      const fortySixManValue = seasons.reduce(
        (a, v) => (a = a + (v && v.rosterBonuses.fortySixMan)),
        0
      );
      const fiftyThreeManValue = seasons.reduce(
        (a, v) => (a = a + (v && v.rosterBonuses.fiftyThreeMan)),
        0
      );
      const ninetyManValue = seasons.reduce(
        (a, v) => (a = a + (v && v.rosterBonuses.ninetyMan)),
        0
      );
      value =
        perGameValue - fortySixManValue - fiftyThreeManValue - ninetyManValue;
      value = value < 0 ? 0 : value;
      break;
    case SectionType.ReportingBonus:
      value = seasons.reduce((a, v) => (a = a + (v && v.reportingBonus)), 0);
      break;
    case SectionType.Offseason:
      value = seasons.reduce((a, v) => (a = a + (v && v.workoutBonus)), 0);
      break;
    case SectionType.Escalator:
      value = seasons.reduce((a, v) => (a = a + (v && v.escalators)), 0);
      break;
    case SectionType.Incentive:
      value = seasons.reduce((a, v) => (a = a + (v && v.incentives)), 0);
      break;
    default:
      value = 0;
      break;
  }
  return value == 0 ? "" : toCurrency(value);
};

export const getContractSectionValue = (
  sections: ContractSectionFragment[],
  sectionName: string
) => {
  let value = 0;
  if (sections.length == 0) return 0;
  switch (sectionName) {
    case SectionType.P5:
      const s = sections.filter((x) => x.capAmt > 0);
      if (s.length > 0) {
        value = s[0].capAmt;
      }
      break;
    case SectionType.SigningBonus:
      value = sections[0].capAmt / 5;
      break;
  }
  return value;
};
export const getContractLineTotalGuaranteed = (
  seasons: ContractSeasonFragment[],
  section: string
) => {
  let value = 0;
  // if void year, we don't count it
  switch (section) {
    case SectionType.P5:
      value = seasons.reduce((a, v) => (a = a + (v ? v.guarantees.p5 : 0)), 0);
      break;
    case SectionType.P5Conv:
      value = seasons.reduce(
        (a, v) => (a = a + (v ? v.guarantees.p5Conversion : 0)),
        0
      );
      break;
    case SectionType.P5Inj:
      value = seasons.reduce(
        (a, v) => (a = a + (v ? v.guarantees.p5Injury : 0)),
        0
      );
      break;
    case SectionType.RosterBonus.NinetyMan:
      value = seasons.reduce(
        (a, v) => (a = a + (v ? v.guarantees.rosterBonus90Man : 0)),
        0
      );
      break;
    case SectionType.RBConv:
      value = seasons.reduce(
        (a, v) => (a = a + (v ? v.guarantees.rosterBonusConversion : 0)),
        0
      );
      break;
    case SectionType.RBInj:
      value = seasons.reduce(
        (a, v) => (a = a + (v ? v.guarantees.rosterBonusInjury : 0)),
        0
      );
      break;
    case SectionType.OffseasonConv:
      value = seasons.reduce(
        (a, v) => (a = a + (v ? v.guarantees.offseasonConversion : 0)),
        0
      );
      break;
    default:
      value = 0;
      break;
  }
  return value == 0 ? "" : toCurrency(value);
};
export const getContractLineTotalGuaranteedFromOverrides = (
  overrides: ContractWorksheetGridItem[],
  section: string
) => {
  let value = 0;
  switch (section) {
    case SectionType.RosterBonus.NinetyMan:
      value = overrides
        .filter((o) => o.item === WorksheetItemEnum.RB90)
        .reduce((a, v) => (a = a + (v ? v.guaranteedAmount ?? 0 : 0)), 0);
      break;
    default:
      value = 0;
      break;
  }
  return value == 0 ? "" : toCurrency(value);
};
export const getContractLineTotalGuaranteedForSeasonFromOverrides = (
  overrides: ContractWorksheetGridItem[],
  section: string,
  season: number
) => {
  let value = 0;
  switch (section) {
    case SectionType.RosterBonus.NinetyMan:
      value = overrides
        .filter((o) => o.item === WorksheetItemEnum.RB90 && o.season == season)
        .reduce((a, v) => (a = a + (v ? v.guaranteedAmount ?? 0 : 0)), 0);
      break;
    default:
      value = 0;
      break;
  }
  return value == 0 ? "" : toCurrency(value);
};

export const removeDuplicateSectionsBySeason = (
  sections: ContractSectionFragment[] | undefined
) => {
  if (sections == undefined) return sections;
  const distinctSections = sections?.filter(
    (cs1: ContractSectionFragment, idx: number) =>
      idx ===
      sections?.findIndex(
        (cs2: ContractSectionFragment) =>
          cs2.sectionText === cs1.sectionText &&
          cs2.sectionType.type === cs1.sectionType.type &&
          cs2.season == cs1.season
      )
  );
  return distinctSections;
};
export const removeDuplicateSections = (
  sections: ContractSectionFragment[] | undefined
) => {
  if (sections == undefined) return sections;
  const distinctSections = sections?.filter(
    (cs1: ContractSectionFragment, idx: number) =>
      idx ===
      sections?.findIndex(
        (cs2: ContractSectionFragment) =>
          cs2.sectionText === cs1.sectionText &&
          cs2.sectionType.type === cs1.sectionType.type
      )
  );
  return distinctSections;
};
